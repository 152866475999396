const ROUTES_GATEWAY = "/app";
export const ROUTES_URL = {
  // BASE_PATH: "/",
  BASE_PATH: "/",


  HOME: "/",
  DASHBOARD: ROUTES_GATEWAY,
  // DASHBOARD_BASE: ROUTES_GATEWAY,
  DASHBOARD_BASE: ROUTES_GATEWAY,
  PRIVACY: `privacy`,

  ANASAYFA: `${ROUTES_GATEWAY}/anasayfa`,
  GOREVLERIM: `${ROUTES_GATEWAY}/gorevlerim`,
  DEGERLENDIRMELERIM: `${ROUTES_GATEWAY}/degerlendirmelerim`,
  KITAPLARIM: `${ROUTES_GATEWAY}/kitaplarim`,
  KITAPQUIZ: `${ROUTES_GATEWAY}/kitap-quiz`,
  ACTIVITIES: `${ROUTES_GATEWAY}/activities`,
  BOOKQUIZRESULT: `${ROUTES_GATEWAY}/activity-result`,
  DRAGANDDROP: `${ROUTES_GATEWAY}/drag-and-drop`,
  BOOK: `${ROUTES_GATEWAY}/book`,
  BOOK_CASE: `${ROUTES_GATEWAY}/bookcase`,
  PAIRING: `${ROUTES_GATEWAY}/pairing`,
  VOICE_TASK: `${ROUTES_GATEWAY}/voice_task`,
  PUZZLE: `${ROUTES_GATEWAY}/puzzle`,

  BENIMDUYAM: `${ROUTES_GATEWAY}/benimdunyam`,
  OYUNLARIM: `${ROUTES_GATEWAY}/oyunlarım`,
  PROFILIM: `${ROUTES_GATEWAY}/profilim`,
  BOOKINFO: `${ROUTES_GATEWAY}/bookinfo`,
  LOGOUT: `${ROUTES_GATEWAY}/logout`,
  BLOG: `${ROUTES_GATEWAY}/blog`,

  LOGIN: "/login",
  NOT_FOUND: "/404",
};

export const IMAGE_BASE_PATH = "/ogretmen/ogretmen/";

export const TEACHER_URL = "http://okudio.com/ogretmen";
export const TEACHER_URL_NEW = "http://okudio.com/ogretmen/login";

const API_GATEWAY = "https://backend.okudio.com/api";
// const API_GATEWAY = "https://mshaadinfotech.in/okouio/api";

export const API_URL = {
  LOGIN: `${API_GATEWAY}/login`,
  LOGOUT: `${API_GATEWAY}/logout`,

  CONTACTUS: `${API_GATEWAY}/contactUs`,

  GET_PROFILE: `${API_GATEWAY}/getprofile`,
  UPDATE_PROFILE: `${API_GATEWAY}/updateProfile`,
  GET_PROFILE_BADGES: `${API_GATEWAY}/badgeList`,

  GET_DASHBOARD_COUNT: `${API_GATEWAY}/studentCards`,

  GET_BOOK_LIST: `${API_GATEWAY}/bookListForUser`,
  GET_BOOK_DETAILS: `${API_GATEWAY}/getBookDetails`, // /id
  GET_BOOK_BY_CATEGORY: `${API_GATEWAY}/getBookbySubCat`, // /id
  GET_FAVORITE_BOOK: `${API_GATEWAY}/favoriteList`,
  GET_ASSIGNED_BOOK: `${API_GATEWAY}/assignedBooksForUSer`,

  LIKE_DISLIKE_BOOK: `${API_GATEWAY}/favoriteUnfavorite`, // id
  UPDATE_BOOK_READ_PAGE: `${API_GATEWAY}/bookReadComplete`,

  GET_BADGE_LIST: `${API_GATEWAY}/badgeList`,

  GET_TASK_HISTORY: `${API_GATEWAY}/studentBookHistory`, // ID

  GET_EVAULATIONS: `${API_GATEWAY}/getUserVoiceSumitList`, // ID


  GET_TASK_MATCHING: `${API_GATEWAY}/getMatchingTaskDetails`,
  GET_QUIZ_LIST: `${API_GATEWAY}/getMcqTaskDetails`,
  GET_DRAGANDDROP: `${API_GATEWAY}/getDragDropTaskDetails`,
  GET_PUZZLE_TASK: `${API_GATEWAY}/getPuzzleTaskDetails`,
  GET_VOICE_TASK: `${API_GATEWAY}/getVoiceTaskDetails`,

  SUBMIT_QUIZ: `${API_GATEWAY}/submitMcqTask`,
  SUBMIT_DRAG_DROP: `${API_GATEWAY}/submitDragDropTask`,
  SUBMIT_MATCHING: `${API_GATEWAY}/submitMatchingTask`,
  SUBMIT_VOICE: `${API_GATEWAY}/submitTask`,
  SUBMIT_PUZZLE: `${API_GATEWAY}/submitPuzzleTask`,

  BOOK_READS_CALC : `${API_GATEWAY}/bookReadTimeCalc`,
  BOOK_PAIRING_CORRECT :  `${API_GATEWAY}/bookPairingCorrect`,
  GET_CONTENT: `${API_GATEWAY}/getAllContentFile`, //"type":"4", //1-Kaynaklar,2-Acadmic,4-Blog
};
 